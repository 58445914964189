import React, { lazy, useEffect } from 'react';
import {
  SubNavMenuCompany,
  SubNavMenuProjects,
  SubNavMenuSourcing,
  // SubNavMenuContracts,
  SubNavMenuTenders,
  SubNavMenuUser,
  SubNavMenuProjectConfig,
  SubNavMenuCompanyConfig,
  SubNavMenuOrder,
  SubNavMenuNews,
  SubNavMenuMagazine,
  SubNavMenuSalesCRM,
  SubNavMenuAdminCRM,
  SubNavMenuRenewalCRM,
  SubNavMenuCSCRM
} from './subNav';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { postRequest } from '../../helpers/httpHelper';
const Home = lazy(() => import('../../screens/Main/Home/Home'));

const ArrayForNav = [
  'project',
  'tender',
  'contract',
  'company',
  'order',
  'sourcing',
  'news',
  'magazine',
  'manage-user',
  'project-configuration',
  'company-configuration',
  'sales-crm',
  'admin-crm',
  'renewal-crm',
  'cs-crm'
];

const ProtectedRoute = ({ children, requiredPermission, path }) => {
  // Assume your redux state holds the user's permissions array
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  // const [componentLoaded, setComponentLoaded] = React.useState(true);
  const [hasAccess, setHasAccess] = React.useState(true);

  const logout = () => {
    postRequest('logout', {}, function (res) {
      if (res?.data?.data?.error) {
        console.log('unable to logout');
      } else {
        if (window.localStorage.getItem('authToken')) {
          window.localStorage.removeItem('authToken');
        }
        navigate('/auth/login');
      }
    });
  };

  useEffect(() => {
    if (user) {
      if (user?.isActive !== 1 || user?.roles?.isActive !== 1) logout();

      const roleAccess = user?.roles?.roleAccess.find((item) => {
        if (item.key === 'user-management' && path === 'manage-user') return true;

        if (
          item.key === 'master-configuration' &&
          (path === 'project-configuration' || path === 'company-configuration')
        )
          return true;
        return item.key === path || null;
      });
      // console.log(roleAccess, requiredPermission, path);
      //
      // Check if the user has all the required permissions
      setHasAccess(
        requiredPermission
          ? requiredPermission.some((permission) =>
              roleAccess ? roleAccess[`${permission}`] === 1 : false
            )
          : true
      );
    }
  }, [user, location]);

  return hasAccess ? children : logout();
};

const getSubNavMenu = (path) => {
  let subRoutes = [];
  switch (path) {
    case 'project':
      subRoutes = SubNavMenuProjects;
      break;
    case 'tender':
      subRoutes = SubNavMenuTenders;
      break;
    // case 'contract':
    //   subRoutes = SubNavMenuContracts;
    //   break;
    case 'sourcing':
      subRoutes = SubNavMenuSourcing;
      break;
    case 'company':
      subRoutes = SubNavMenuCompany;
      break;
    case 'order':
      subRoutes = SubNavMenuOrder;
      break;
    case 'news':
      subRoutes = SubNavMenuNews;
      break;
    case 'magazine':
      subRoutes = SubNavMenuMagazine;
      break;
    case 'manage-user':
      subRoutes = SubNavMenuUser;
      break;
    case 'project-configuration':
      subRoutes = SubNavMenuProjectConfig;
      break;
    case 'company-configuration':
      subRoutes = SubNavMenuCompanyConfig;
      break;
    case 'sales-crm':
      subRoutes = SubNavMenuSalesCRM;
      break;
    case 'admin-crm':
      subRoutes = SubNavMenuAdminCRM;
      break;
    case 'renewal-crm':
      subRoutes = SubNavMenuRenewalCRM;
      break;
    case 'cs-crm':
      subRoutes = SubNavMenuCSCRM;
      break;
    default:
      subRoutes = [];
  }

  // Wrap routes that have a requiredPermission property
  const filteredRoutes = subRoutes.map((route) => {
    if (route.requiredPermission && React.isValidElement(route.element)) {
      return {
        ...route,
        element: (
          <ProtectedRoute requiredPermission={route.requiredPermission} path={path}>
            {route.element}
          </ProtectedRoute>
        )
      };
    }
    return route;
  });

  return filteredRoutes;
};

const superAdminNav = ArrayForNav?.map((path) => {
  return {
    path: path,
    element: <Outlet />,
    children: getSubNavMenu(path)
  };
});

export default superAdminNav;
