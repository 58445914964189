
// the fourth segment (index 3) of the pathname is undefined, and you're trying to call toLowerCase() on it.
// export const getUrlParamFromPathnameByIndex = (pathname, index) => {

//     return pathname.split('/')[index].toLowerCase()
// };

// Modify the getUrlParamFromPathnameByIndex function to handle cases where the requested index doesn't exist.
export const getUrlParamFromPathnameByIndex = (pathname, index) => {
    const segments = pathname.split('/');
    return segments[index] || '';
  };