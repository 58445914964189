import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
  leadInfo: {},
  leadNotes: ''
};

const leadReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes?.SET_LEADINFO:
      return { ...state, leadInfo: { ...state.leadInfo, ...payload } };
    case actionTypes?.SET_LEADNOTES:
      return { ...state, leadNotes: payload };
    default:
      return state;
  }
};

export default leadReducer;
