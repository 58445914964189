import React, { useState } from 'react';
// import { defaultSnackBarState } from '../../../../utils/common';
import { Box, Divider, Modal, Typography } from '@mui/material';
// import OrderProvider from '../../../../main/contexts/orderContext';
// import SnackbarAlert from '../../../../components/Drawers/SnackbarAlert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MagazineTable from './MagazineTable/MagazineTable';
import { defaultSnackBarState } from '../../../utils/common';
import SnackbarAlert from '../../../components/Drawers/SnackbarAlert';
import AddMagazine from './AddMagazine/AddMagazine';
// import FilterOrderSearchForm from './OrderFilter/FilterOrderSearchForm';
// import OrderTable from './OrderTable/OrderTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '80%'
};

const closeBoxStyle = {
  position: 'absolute',
  top: '-1px',
  left: '-50px',
  bgcolor: 'orange',
  width: 50,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 0 0 20px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
};

const Magazine = () => {
  const [enableMagazineSearchForm, setMagazineSearchForm] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [modalState, setModalState] = React.useState(null);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [snackbarState, setSnackbarState] = useState(defaultSnackBarState);
  //   const [filterMagazine, setFilterMagazine] = useState({
  //     any: '',
  //     orderId: '',
  //     orderText: '',
  //     orderValue: '',
  //     value: '',
  //     industry: [],
  //     state: '',
  //     district: '',
  //     awardeeType: '',
  //     contractModel: '',
  //     statusByImpStage: '',
  //     awaedee_ID: '',
  //     awardeeName: '',
  //     awarder_ID: '',
  //     originContract: '',
  //     addedDateFrom: '',
  //     addedDateTo: '',
  //     modifiedDateFrom: '',
  //     modifiedDateTo: '',
  //     orderDateFrom: '',
  //     orderDateTo: '',
  //     fboDateFrom: '',
  //     fboDateTo: '',
  //   });

  const openModal = (type, rowData = null) => {
    setModalState(type);
    setSelectedRowData(rowData);
    // console.log(rowData);
  };

  const closeModal = () => {
    setModalState(null);
  };

  const showSnackBar = (type, message) => {
    setSnackbarState({
      show: true,
      type: type,
      message: message,
      vertical: 'bottom',
      horizontal: 'center'
    });
    hideSnackBar();
  };

  const hideSnackBar = () => {
    setTimeout(() => {
      setSnackbarState(defaultSnackBarState);
    }, 3000);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setMagazineSearchForm(false);
    }
    // setMagazineSearchForm(false);
  };

  return (
    <>
      <Modal
        sx={{ overflowY: 'scroll' }}
        open={enableMagazineSearchForm}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={closeBoxStyle}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
          </Box>
          <Typography sx={{ fontSize: 'large', color: 'black', fontWeight: 500, mb: '6px' }}>
            Magazine Search
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, borderColor: '#fffff' }} />
          <Box>
            {/* <FilterOrderSearchForm
                setSnackbarState={setSnackbarState}
                filterOrders={filterOrders}
                setFilterOrders={setFilterOrders}
                setRefreshTable={setRefreshTable}
                setEnableOrdersSearchForm={setEnableOrdersSearchForm}
              /> */}
          </Box>
          {/* {snackbarState?.show && (
              <SnackbarAlert
                open={snackbarState?.show}
                message={snackbarState?.message}
                severity={snackbarState?.type}
                vertical={snackbarState?.vertical}
                horizontal={snackbarState?.horizontal}
              />
            )} */}
        </Box>
      </Modal>

      <Modal
        sx={{ overflowY: 'scroll' }}
        open={!!modalState}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              position: 'absolute',
              top: '-1px',
              left: '-50px',
              bgcolor: 'orange',
              width: 50,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '20px 0 0 20px',
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
            }}>
            <IconButton onClick={() => closeModal()}>
              <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
          </Box>
          {modalState === 'magazine' && (
            <AddMagazine
              closeModal={closeModal}
              showSnackBar={showSnackBar}
              rowData={selectedRowData}
              setRefreshTable={setRefreshTable}
            />
          )}
        </Box>
      </Modal>

      <Box
        sx={{
          mt: 1,
          mb: 1,
          padding: '28px'
        }}>
        <Box sx={{ mt: '10px' }}>
          <MagazineTable
            // setMagazineSearchForm={setMagazineSearchForm}
            openModal={openModal}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
          />
        </Box>
      </Box>

      {snackbarState?.show && (
        <Box sx={{ padding: 2, margin: 2 }}>
          <SnackbarAlert
            open={snackbarState?.show}
            message={snackbarState?.message}
            severity={snackbarState?.type}
            vertical={snackbarState?.vertical}
            horizontal={snackbarState?.horizontal}
          />
        </Box>
      )}
    </>
  );
};

export default Magazine;
