import React, { useEffect } from 'react';
import {
  MaterialReactTable,
  MRT_ActionMenuItem,
  useMaterialReactTable
} from 'material-react-table';
import { Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getUrlParamFromPathnameByIndex } from '../../helpers/commonHelpers';
import { useSelector } from 'react-redux';

function Table({
  data,
  columns,
  isLoading,
  isError,
  rowSelection,
  setRowSelection,
  globalFilter,
  setGlobalFilter,
  sorting,
  setSorting,
  pagination,
  setPagination,
  rowCount,
  toolbarButtons,
  rowActionItems = [],
  state,
  children
}) {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = location;

  const [permissions, setPermissions] = React.useState(null);
  const [finalState, setFinalState] = React.useState({});

  useEffect(() => {
    setFinalState(state);
  }, [state]);

  useEffect(() => {
    if (!user?.roles?.roleAccess) return;
    setPermissions(
      user?.roles?.roleAccess?.find(({ key }) => {
        if (
          key === 'user-management' &&
          getUrlParamFromPathnameByIndex(pathname, 2) === 'manage-user'
        )
          return true;

        if (
          key === 'master-configuration' &&
          (getUrlParamFromPathnameByIndex(pathname, 2) === 'project-configuration' ||
            getUrlParamFromPathnameByIndex(pathname, 2) === 'company-configuration')
        )
          return true;
        return key === getUrlParamFromPathnameByIndex(pathname, 2);
      })
    );
  }, [user]);

  useEffect(() => {
    if (!permissions) return;
    if (permissions.view === 1) {
      setFinalState((prev) => ({
        ...prev,
        enableRowActions: false,
        enableRowSelection: false,
        enableMultiRowSelection: false
      }));
    }
  }, [permissions]);

  // Create the column order array with selection and action columns
  const fullColumnOrder = [
    'mrt-row-select', // Selection checkbox column
    ...columns?.map((col) => col?.accessorKey), // Your defined columns
    'mrt-row-actions' // Actions column
  ];

  // MaterialReactTable configuration
  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableStickyHeader: true,
    enableColumnActions: false,
    positionActionsColumn: 'last',
    // enableRowSelection: true,
    enableGlobalFilter: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    getRowId: (row) => row.Id,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
      columnOrder: fullColumnOrder
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        enableHiding: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableColumnOrdering: false,
        enableResizing: false,
        enableSorting: false
      },
      'mrt-row-actions': {
        enableHiding: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableColumnOrdering: false,
        enableResizing: false,
        enableSorting: false
      }
    },
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    enableRowActions: true,
    enableSortingRemoval: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data'
        }
      : undefined,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: () => ({
        maxHeight: 'calc(100vh - 300px)'
      })
    },
    muiTableHeadCellProps: {
      sx: () => ({
        backgroundColor: '#f3f6f9',
        fontWeight: '600',
        fontSize: '1rem',
        borderBottomWidth: '1px',
        paddingTop: '0.7rem',
        paddingBottom: '0.7rem',
        textWrap: 'nowrap'
      })
    },
    renderTopToolbarCustomActions: () => {
      if (!toolbarButtons || !toolbarButtons.length) return null;

      const filteredButtons = permissions
        ? toolbarButtons.filter((button) => {
            if (button.label?.toLowerCase()?.includes('add')) {
              return permissions.add === 1 || permissions.delete === 1;
            }
            return true;
          })
        : toolbarButtons;

      return (
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          {filteredButtons.map((button, index) => (
            <Button
              key={index}
              variant={button.variant}
              sx={button.sx}
              color={button.color}
              onClick={button.onClick}
              startIcon={button.startIcon}>
              {button.label}
            </Button>
          ))}
          {children}
        </Box>
      );
    },
    renderRowActionMenuItems: ({ closeMenu, row, table }) => {
      // Return early if there are no buttons
      if (!rowActionItems || !rowActionItems.length || permissions?.view === 1) return null;

      // Filter buttons based on permissions if they exist
      const filteredButtons = permissions
        ? rowActionItems.filter((button) => {
            if (button.label === 'Edit') {
              return permissions.add === 1 || permissions.delete === 1;
            }
            if (button.label === 'Delete') {
              return permissions.delete === 1;
            }
            return true;
          })
        : rowActionItems;

      return filteredButtons.map((item, index) => (
        <MRT_ActionMenuItem
          key={index}
          icon={item.icon}
          label={item.label}
          onClick={() => {
            closeMenu();
            item.onClick(row.original, row.index);
          }}
          table={table}
        />
      ));
    },
    rowCount,
    ...finalState,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      sorting,
      rowSelection,
      columnOrder: fullColumnOrder
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
}

export default Table;
