import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from '../../library/common/reducers/AuthReducer';
import companyReducer from './companySlice';
import projectReducer from './projectSlice';
import tendersReducer from './tenderSlice';
import orderReducer from './orderSlice';
import newsReducer from './newsSlice';
import leadReducer from './leadSlice';
import agencyReducer from './agencySlice';
import updateSubMenuCountReducer from '../../library/common/reducers/updateSubMenuCountReducer';
import copyLeadContextSliceReducer from './copyLeadContextSlice';
import subscriptionReducer from './subscriptionSlice'

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    company: companyReducer,
    tender: tendersReducer,
    project: projectReducer,
    agency: agencyReducer,
    order: orderReducer,
    news: newsReducer,
    update: updateSubMenuCountReducer,
    lead: leadReducer,
    subscription: subscriptionReducer,
    copyLead: copyLeadContextSliceReducer
  }
});

export default store;
