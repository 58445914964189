import dayjs, { utc } from 'dayjs';
import * as Yup from 'yup';

export const removeAuthUserFromStorage = () => {
  window.localStorage.removeItem('authToken');
};

export const setTokenToStorage = (token) => {
  window.localStorage.setItem('authToken', token);
};

export const defaultSnackBarState = {
  show: false,
  type: 'success',
  message: '',
  vertical: 'bottom',
  horizontal: 'center'
};

export const getTokenFromStorage = () => {
  const token = window.localStorage.getItem('authToken');
  return token;
};

export const validationRegex = {
  pincode: /^[0-9][0-9\- ]{0,10}[0-9]$/,
  // email: /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
  email: /^((?!\.)[\w\-_.]*[^.])(@[\w-]+)(\.\w+(\.\w+)?[^.\W])$/gm, // accepts hyphen now
  mobile: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  direct: /^(\+\d{1,3}[- ]?)?\d{8}$/,
  stdcode: /^(?!\s|.*\s$)\d{1,3}$/,
  extension: /^(?!\s|.*\s$)(?!\s*$).+/,
  latitude: /^[-+]?(([1-8]?\d(\.\d+)?)|90(\.0+)?)$/,
  longitude: /^[-+]?((([1-9]?\d|1[0-7]\d)(\.\d+)?)|180(\.0+)?)$/,
  // url: /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  url: /[-a-zA-Z0-9@:%_\+.~#?&//=]{1,256}\.[a-z]{1,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  other: /^(?!\s|.*\s$)(?!\s*$).+/,
  description: /^(?!\s*$).+/,
  blankSpace: /^(?!\s*$).+/,
  blankSpacesMessage: 'Input cannot be empty or contain only spaces',
  // filterLimitedAndCorporation: /^(?=.*\b(?:limited|corporation)\b).+$/,
  filterLimitedAndCorporation: /^(?!.*\b(limited|corporation)\b).*$/i,
  gstRegex: /^[A-Za-z0-9]{15}$/
  // blankSpace: /^(?!\s*$).+/,
};

// from date must be earlier than to date
export const toFromDateValidationSchema = (fromKey = 'fromDate', toKey = 'toDate', labels = {}) => {
  const fromLabel = labels[fromKey] || fromKey;
  const toLabel = labels[toKey] || toKey;

  return {
    [fromKey]: Yup.date()
      .nullable()
      .max(Yup.ref(toKey), `${fromLabel} must be earlier than or the same as ${toLabel}`),
    [toKey]: Yup.date()
      .nullable()
      .min(Yup.ref(fromKey), `${toLabel} must be later than or the same as ${fromLabel}`)
  };
};

// if either is filled other is mandatory
export const createDatePairValidation = (
  fromKey,
  toKey,
  errorMessage = 'Both dates are required'
) => {
  return {
    [fromKey]: Yup.string()
      .nullable()
      .test(`${fromKey}-${toKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[toKey];
        if (!value && !otherDate) return true;
        if (value && otherDate) return true;
        return false;
      }),
    [toKey]: Yup.string()
      .nullable()
      .test(`${toKey}-${fromKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[fromKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      })
  };
};

// combination of above
export const combinedDateValidationSchema = (
  fromKey = 'fromDate',
  toKey = 'toDate',
  labels = {},
  errorMessage = 'Both dates are required'
) => {
  const fromLabel = labels[fromKey] || fromKey;
  const toLabel = labels[toKey] || toKey;

  return {
    // Validation for the from date (earlier or equal to to date)
    [fromKey]: Yup.date()
      .nullable()
      .max(Yup.ref(toKey), `${fromLabel} must be earlier than or the same as ${toLabel}`)
      .test(`${fromKey}-${toKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[toKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      }),

    // Validation for the to date (later or equal to from date)
    [toKey]: Yup.date()
      .nullable()
      .min(Yup.ref(fromKey), `${toLabel} must be later than or the same as ${fromLabel}`)
      .test(`${toKey}-${fromKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[fromKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      })
  };
};

export const titlesData = [
  { label: 'Dr.' },
  { label: 'Mr.' },
  { label: 'Ms.' },
  { label: 'Prof.' }
];

export const formatDateFields = (date) => {
  if (date) {
    return dayjs(date)?.format('YYYY-MM-DD');
  } else {
    return '';
  }
};

export const formatAddress = (...args) => {
  const arr = [args];
  return arr.filter((e) => e).join(', ');
};

export const trimPayloadDeepOptimized = (payload) => {
  if (payload === null || typeof payload !== 'object') return payload;

  if (Array.isArray(payload)) {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = trimPayloadDeepOptimized(payload[i]);
    }
    return payload;
  }

  const result = {};
  for (const [key, value] of Object.entries(payload)) {
    result[key] = typeof value === 'string' ? value.trim() : trimPayloadDeepOptimized(value);
  }
  return result;
};

export default function removeBlankSpaces(obj) {
  function trimValues(value) {
    if (typeof value === 'string') {
      return value.trim();
    } else if (Array.isArray(value)) {
      return value.map(trimValues);
    } else if (value !== null && typeof value === 'object') {
      return removeBlankSpaces(value);
    }
    return value;
  }

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = trimValues(obj[key]);
    }
  }
  return obj;
}

// modal style
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '85%'
};

// close modal style
export const closeBoxStyle = {
  position: 'absolute',
  top: '-1px',
  left: '-50px',
  bgcolor: 'orange',
  width: 50,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 0 0 20px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
};

export const coordinatesSheetTemplate = [{ latitude: '19.02', longitude: '72.51' }];

export const CrNumberFormat = (value) => {
  // Add Thousand Separator and limit to 2 decimal digits
  if (typeof value === 'string') {
    return value
      .replace(/[^\d.]/g, '')
      .replace(/\.(?=.*\.)/g, '')
      .replace(/^(\d*\.\d{0,2})\d*$/, '$1')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return value;
  }
};

export const numberToWordsIndian = (num) => {
  if (num === 0) return 'zero';

  const a = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ];

  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  const convertToWords = (n) => {
    if (n < 20) return a[n];
    if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? ' ' + a[n % 10] : '');
    if (n < 1000)
      return (
        a[Math.floor(n / 100)] + ' hundred' + (n % 100 ? ' and ' + convertToWords(n % 100) : '')
      );
    if (n < 100000)
      return (
        convertToWords(Math.floor(n / 1000)) +
        ' thousand' +
        (n % 1000 ? ' ' + convertToWords(n % 1000) : '')
      );
    if (n < 10000000)
      return (
        convertToWords(Math.floor(n / 100000)) +
        ' lakh' +
        (n % 100000 ? ' ' + convertToWords(n % 100000) : '')
      );
    return (
      convertToWords(Math.floor(n / 10000000)) +
      ' crore' +
      (n % 10000000 ? ' ' + convertToWords(n % 10000000) : '')
    );
  };

  return convertToWords(num);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const handlePrint = (printRef) => {
  const printContent = printRef.current;
  const printWindow = window.open('', '_blank');
  printWindow.document.write('<html><head><title>Print</title>');
  printWindow.document.write(`
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
          }
          .common-view-container {
              max-width: 1400px;
              margin: 0 auto;
              padding: 20px;
              background-color: #fff;
          }
          .main-title {
              font-weight: 500;
              text-align: left;
              background-color: #FEA434;
              color: #fff;
              padding: 12px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
          }
          .paper {
              overflow: hidden;
              border: 1px solid #FEA535;
              border-radius: 0px;
              margin-top: 20px;
          }
          .key-title {
              font-weight: 600;
              background-color: #FEE698;
              color: #000;
              padding: 10px;
          }
          table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
          }
          th, td {
              border: 1px solid #FEA535;
              padding: 8px;
              font-size: 0.9rem;
              color: #000;
          }
          th {
              text-align: left;
              font-weight: bold;
              background-color: #FEE698;
          }
          tr:nth-child(even) {
              background-color: #FFF3CC;
          }
          tr:nth-child(odd) {
              background-color: #FFFFFF;
          }
          a {
              color: #e6732f;
              text-decoration: none;
          }
      </style>
  `);

  printWindow.document.write('</head><body>');
  printWindow.document.write('<div class="common-view-container">');
  printWindow.document.write(printContent.innerHTML);
  printWindow.document.write('</div></body></html>');
  printWindow.document.close();

  printWindow.print();

  setTimeout(() => printWindow.close(), 100);
};

export const getDocumentApiBasedOnPath = (path) => {
  switch (path) {
    case 'order':
      return {
        create: 'create-order-document',
        update: 'update-order-document',
        list: 'list-order-document',
        fetch: 'fetch-order-document',
        idKey: 'order_ID',
        paramsKey: 'orderID',
        document_ID: 'orderDocument_ID',
        document_ID_Key: 'id'
      };
    case 'tender':
      return {
        create: 'create-tender-document',
        update: 'update-tender-document',
        list: 'list-tender-document',
        fetch: 'fetch-tender-document',
        idKey: 'tender_ID',
        paramsKey: 'tenderID',
        document_ID: 'tenderDocument_ID',
        document_ID_Key: 'id'
      };

    case 'news':
      return {
        create: 'create-news-document',
        update: 'update-news-document',
        list: 'list-news-document',
        fetch: 'fetch-news-document',
        idKey: 'news_ID',
        paramsKey: 'newsID',
        document_ID: 'newsDocument_ID',
        document_ID_Key: 'id'
      };

    case 'admin-crm':
      return {
        create: 'create-update-subscription-document',
        update: 'create-update-subscription-document',
        list: 'list-subscription-document',
        fetch: 'fetch-subscription-document',
        idKey: 'accountSubscription_ID',
        paramsKey: 'subscriptionID',
        document_ID: 'accountSubscriptionDocument_ID',
        document_ID_Key: 'accountSubscriptionDocument_ID'
      };
    default:
      console.log(`Invalid Path`);
  }
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '80%'
};

export const modalCloseBoxStyle = {
  position: 'absolute',
  top: '-1px',
  left: '-50px',
  bgcolor: 'orange',
  width: 50,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 0 0 20px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
};

export const month = [
  // { id: 1, name: 'January' },
  // { id: 2, name: 'February' },
  // { id: 3, name: 'March' },
  // { id: 4, name: 'April' },
  // { id: 5, name: 'May' },
  // { id: 6, name: 'June' },
  // { id: 7, name: 'July' },
  // { id: 8, name: 'August' },
  // { id: 9, name: 'September' },
  // { id: 10, name: 'October' },
  // { id: 11, name: 'November' },
  // { id: 12, name: 'December' }
  { id: 'January', name: 'January' },
  { id: 'February', name: 'February' },
  { id: 'March', name: 'March' },
  { id: 'April', name: 'April' },
  { id: 'May', name: 'May' },
  { id: 'June', name: 'June' },
  { id: 'July', name: 'July' },
  { id: 'August', name: 'August' },
  { id: 'September', name: 'September' },
  { id: 'October', name: 'October' },
  { id: 'November', name: 'November' },
  { id: 'December', name: 'December' }
];

export const handleTouchOnTabSwitch = (formInitialValues, formikRef) => {
  Object.entries(formInitialValues).forEach(([key, value]) => {
    if (formikRef && formikRef.current) {
      formikRef.current.setFieldTouched(key, true);
    }
  });
};

export function getStatusDisplayName(status) {
  switch (
    status?.toLowerCase() // Ensure case-insensitivity
  ) {
    case 'draft':
      return 'Draft';
    case 'rejected':
      return 'Rejected';
    case 'pendingapproval':
      return 'Pending Approval';
    case 'publish':
      return 'Publish';
    default:
      return ''; // Default value if no match
  }
}

// Compare Common Logic for all module.
export const getColor = (color) => {
  switch (color) {
    case 'red':
      return '#FF0000'; // Hex code for red
    case 'blue':
      return '#0000FF'; // Hex code for blue
    case 'green':
      return '#008000'; // Hex code for green
    default:
      return '#000000'; // Default to black if no color matches
  }
};

export const getColouredAnchor = (data) => {
  if (data?.color === 'red' && data?.oldInfo?.type === 'a') {
    return (
      <a
        href={data.oldInfo.props.href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#FF0000' }}>
        {data.oldInfo.props.children}
      </a>
    );
  } else if (data?.currInfo?.type === 'a') {
    return (
      <a
        href={data.currInfo.props.href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: data.color ? getColor(data.color) : '#111112' }}>
        {data.currInfo.props.children}
      </a>
    );
  }
  return data?.currInfo || '';
};

export const areArraysEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item, index) => {
    if (typeof item === 'object') {
      return JSON.stringify(item) === JSON.stringify(arr2[index]);
    }
    return item === arr2[index];
  });
};

export const areLinksEqual = (oldLink, newLink) => {
  // If both are empty, they're equal
  if (!oldLink && !newLink) return true;

  // If one is empty and other isn't, they're different
  if (!oldLink || !newLink) return false;

  // Compare the actual URLs from the anchor props
  const oldUrl = oldLink.props?.href || '';
  const newUrl = newLink.props?.href || '';
  return oldUrl === newUrl;
};

export const colorConditions = (item) => {
  // console.log('item => ', item);
  // Handle category comparison
  if (Array.isArray(item.oldInfo) && Array.isArray(item.currInfo)) {
    // return areArraysEqual(item.oldInfo, item.currInfo) ? null : 'blue';
    if (item?.oldInfo?.length === 0 && item?.currInfo?.length > 0) {
      return 'green';
    } else {
      return areArraysEqual(item.oldInfo, item.currInfo) ? null : 'blue';
    }
  }

  // Handle social media links comparison
  if (item.oldInfo?.type === 'a' || item.currInfo?.type === 'a') {
    if (!areLinksEqual(item.oldInfo, item.currInfo)) {
      if (!item.currInfo) return 'red';
      if (!item.oldInfo) return 'green';
      return 'blue';
    }
    return null;
  }

  // Handle regular field comparison
  if (item.oldInfo && item.currInfo && item.oldInfo !== item.currInfo) {
    return 'blue';
  } else if (!item.oldInfo && item.currInfo) {
    return 'green';
  } else if (item.oldInfo && !item.currInfo) {
    item.currInfo = item.oldInfo;
    return 'red';
  }

  return null;
};

// Update the addColorToData function to use the new colorConditions
export const addColorToData = (dataObject) => {
  const processData = (data) => {
    return data?.map((item) => ({
      ...item,
      color: colorConditions(item)
    }));
  };

  const result = {};
  Object.keys(dataObject).forEach((key) => {
    result[key] = processData(dataObject[key]);
  });

  // console.log('result => ', result);
  return result;
};

// export const addColorToCoordinates = (coordinates) => {
//   console.log("coordinates => ", coordinates);
//   if (!coordinates?.currInfo || !Array.isArray(coordinates.currInfo)) {
//     console.error('Invalid coordinates input');
//     return coordinates;
//   }

//   const modifiedCoordinates = { ...coordinates, currInfo: [...coordinates.currInfo] };

//   console.log("modified coordinates => ", modifiedCoordinates)

//   const oldInfo = coordinates.oldInfo || [];
//   const currInfo = modifiedCoordinates.currInfo;

//   console.log("old Infooo 1 => ", oldInfo);
//   console.log("curr Infooo 2 => ", currInfo);

//   // Ensure currInfo has the same length as oldInfo
//   const currLength = currInfo.length;
//   const oldLength = oldInfo.length;

//   console.log("Infooo 3 length => ", currLength);
//   console.log("Infooo 4 length => ", oldLength);

//   if (currLength < oldLength) {
//     const difference = oldLength - currLength;
//     console.log("Infooo 5 difference => ", difference);
//     // Add empty objects to currInfo
//     for (let i = 0; i < difference; i++) {
//       currInfo.push({});
//     }
//   }

//   console.log("curr after psuhing Infooo 6 => ", currInfo);

//   // Compare coordinates
//   currInfo.forEach((currInfoItem, index) => {
//     const oldInfoItem = oldInfo[index] || {};

//     console.log("oldInfo data get Infooo 7 => ", oldInfoItem);

//     // Check latitude and longitude comparison for each coordinate
//     const isLatitudeSame = oldInfoItem.latitude === currInfoItem.latitude;
//     const isLongitudeSame = oldInfoItem.longitude === currInfoItem.longitude;

//     console.log("latitude Infooo 8 => ", isLatitudeSame);
//     console.log("longitude Infooo 9 => ", isLongitudeSame);

//     // Assign colors based on conditions

//     // Latitude logic
//     if (oldInfoItem.latitude && currInfoItem.latitude && !isLatitudeSame) {
//       currInfoItem.latitudeColor = 'blue'; // Different value
//     } else if (!oldInfoItem.latitude && currInfoItem.latitude) {
//       currInfoItem.latitudeColor = 'green'; // New value added
//     } else if (oldInfoItem.latitude && !currInfoItem.latitude) {
//       currInfoItem.latitude = oldInfoItem.latitude; // Restore old value
//       currInfoItem.latitudeColor = 'red'; // Removed value
//     }

//     // Longitude logic
//     if (oldInfoItem.longitude && currInfoItem.longitude && !isLongitudeSame) {
//       currInfoItem.longitudeColor = 'blue'; // Different value
//     } else if (!oldInfoItem.longitude && currInfoItem.longitude) {
//       currInfoItem.longitudeColor = 'green'; // New value added
//     } else if (oldInfoItem.longitude && !currInfoItem.longitude) {
//       currInfoItem.longitude = oldInfoItem.longitude; // Restore old value
//       currInfoItem.longitudeColor = 'red'; // Removed value
//     }
//   });

//   return modifiedCoordinates;
// };

// Below logic working.
export const addColorToCoordinates = (coordinates) => {
  // console.log('coordinates => ', coordinates);
  if (!coordinates?.currInfo || !Array.isArray(coordinates.currInfo)) {
    console.error('Invalid coordinates input');
    return coordinates;
  }

  const modifiedCoordinates = { ...coordinates, currInfo: [...coordinates.currInfo] };
  const oldInfo = coordinates.oldInfo || [];
  const currInfo = modifiedCoordinates.currInfo;

  // Create a map of oldInfo for efficient lookup
  const oldInfoMap = oldInfo.reduce((map, item) => {
    const key = `${item.latitude}-${item.longitude}`;
    map[key] = { ...item };
    return map;
  }, {});

  const seenOldKeys = new Set();

  // Process currInfo
  currInfo.forEach((currInfoItem) => {
    const key = `${currInfoItem.latitude}-${currInfoItem.longitude}`;
    const matchedOldItem = oldInfoMap[key];

    if (matchedOldItem) {
      // Exact match found
      currInfoItem.latitudeColor = null;
      currInfoItem.longitudeColor = null;
      seenOldKeys.add(key);
    } else {
      // No exact match found, check for updates
      const oldItemWithSameIndex = oldInfo.find(
        (oldItem) =>
          (oldItem.latitude === currInfoItem.latitude &&
            oldItem.longitude !== currInfoItem.longitude) ||
          (oldItem.longitude === currInfoItem.longitude &&
            oldItem.latitude !== currInfoItem.latitude)
      );

      if (oldItemWithSameIndex) {
        // Latitude logic
        if (oldItemWithSameIndex.latitude !== currInfoItem.latitude) {
          currInfoItem.latitudeColor = 'blue'; // Updated value
        } else {
          currInfoItem.latitudeColor = null; // No change
        }

        // Longitude logic
        if (oldItemWithSameIndex.longitude !== currInfoItem.longitude) {
          currInfoItem.longitudeColor = 'blue'; // Updated value
        } else {
          currInfoItem.longitudeColor = null; // No change
        }

        seenOldKeys.add(`${oldItemWithSameIndex.latitude}-${oldItemWithSameIndex.longitude}`);
      } else {
        // New entries
        currInfoItem.latitudeColor = 'green';
        currInfoItem.longitudeColor = 'green';
      }
    }
  });

  // Add missing entries from oldInfo that are not in currInfo
  oldInfo.forEach((oldItem) => {
    const key = `${oldItem.latitude}-${oldItem.longitude}`;
    if (!seenOldKeys.has(key)) {
      currInfo.push({
        ...oldItem,
        latitudeColor: 'red',
        longitudeColor: 'red'
      });
    }
  });

  return modifiedCoordinates;
};

export const addColorToFunding = (funding) => {
  if (!funding?.currInfo || !Array.isArray(funding.currInfo)) {
    console.error('Invalid funding input');
    return funding;
  }

  const oldInfo = funding.oldInfo || [];
  const currInfo = funding.currInfo;

  const oldSet = new Set(oldInfo.map((item) => item.trim()));
  const currSet = new Set(currInfo.map((item) => item.trim()));

  // Track the processed items and colors
  const result = [];

  // Check for items that are in currInfo
  currInfo.forEach((currValue) => {
    const trimmedCurrValue = currValue.trim();
    if (!oldSet.has(trimmedCurrValue)) {
      // New value (exists in currInfo but not in oldInfo)
      result.push({ value: currValue, color: 'green' });
    } else {
      // Same value as in oldInfo
      result.push({ value: currValue, color: '' });
    }
  });

  // Check for items that are in oldInfo but not in currInfo
  oldInfo.forEach((oldValue) => {
    const trimmedOldValue = oldValue.trim();
    if (!currSet.has(trimmedOldValue)) {
      // Removed value (exists in oldInfo but not in currInfo)
      result.push({ value: oldValue, color: 'red' });
    }
  });

  return {
    ...funding,
    currInfo: result
  };
};

export const checkUndefinedKeysInObject = (setValueInField) => {
  if (!setValueInField || typeof setValueInField !== 'object') {
    return false;
  }
  return Object.entries(setValueInField).every(([_, value]) => Boolean(value));
};

// export const handlePrintInvoice = (printRef) => {
//   const printContent = printRef.current;
//   const printWindow = window.open('', '_blank');
//   printWindow.document.write('<html><head>');
//   //   const styles = `*{
//   //   box-sizing: border-box;
//   //   padding: 0;
//   //   margin: 0;
//   //   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   //   font-size: 12px;
//   // }

//   // body{
//   //   padding: 5px;
//   // }
//   // #print-invoice-container {
//   //   border: 1px solid black;
//   //   height: 100%;
//   //   width: 100%;
//   // }

//   // #print-invoice-header {
//   //   display: flex;
//   //   align-items: center;
//   //   justify-content: space-between;
//   //   background-color: rgb(255, 255, 255);
//   //   padding: 10px;
//   // }

//   // #print-invoice-title {
//   //   width: 100%;
//   //   text-align: center;
//   //   padding: 10px;
//   //   border-top: 1px solid black;
//   //   border-bottom: 1px solid black;
//   //   background-color: rgb(254, 224, 178);
//   // }

//   // #print-invoice-invoice-table {
//   //   border: 1px solid black;
//   // }

//   // #print-invoice-invoice-details {
//   //   padding: 10px;
//   //   display: flex;
//   //   align-items: center;
//   //   justify-content: space-between;
//   // }

//   // .print-invoice-th,
//   // .print-invoice-td {
//   //   padding: 5px;
//   //   text-align: left;
//   // }

//   // #print-invoice-desc-heading,
//   // #print-invoice-footer-table {
//   //   width: 100%;
//   //   text-align: center;
//   //   padding: 10px;
//   //   border-top: 1px solid black;
//   //   border-bottom: 1px solid black;
//   //   background-color: rgb(254, 224, 178);
//   // }

//   // #print-invoice-description-table,
//   // #print-invoice-footer-table {
//   //   width: 100%;
//   // }

//   // #print-invoice-sr-no {
//   //   width: 10%;
//   // }

//   // #print-invoice-desc {
//   //   width: 50%;
//   // }

//   // #print-invoice-sac {
//   //   width: 10%;
//   // }

//   // #print-invoice-qty {
//   //   width: 10%;
//   // }

//   // #print-invoice-rate {
//   //   width: 10%;
//   // }

//   // #print-invoice-amount {
//   //   width: 10%;
//   // }

//   // #print-invoice-amountInWords {
//   //   width: 10%;
//   // }

//   // #print-invoice-words {
//   //   width: 50%;
//   // }

//   // #print-invoice-totalAmount {
//   //   width: 30%;
//   // }

//   // .print-invoice-desc-th {
//   //   padding: 10px;
//   //   border-top: 1px solid black;
//   //   border-left: 1px solid black;
//   //   text-align: center;
//   // }

//   // #print-invoice-bank-details {
//   //   padding: 10px;
//   //   border-top: 1px solid black;
//   // }

//   // #print-invoice-amount-details {
//   //   padding: 10px;
//   //   border-top: 1px solid black;
//   //   border-left: 1px solid black;
//   // }

//   // #print-invoice-bottom {
//   //   width: 100%;
//   //   display: flex;
//   //   align-items: stretch;
//   // }

//   // #print-invoice-terms {
//   //   width: 60%;
//   //   padding: 10px;
//   //   border-right: 1px solid black;
//   // }

//   // #print-invoice-certified-text {
//   //   text-align: center;
//   // }

//   // #print-invoice-certified-section {
//   //   width: 40%;
//   //   padding: 10px;
//   //   display: flex;
//   //   align-items: stretch;
//   // }

//   // #print-invoice-certified {
//   //   width: 100%;
//   //   display: flex;
//   //   flex-direction: column;
//   //   justify-content: space-between;
//   //   align-items: center;
//   //   gap: 105px;
//   // }

//   // .print-invoice-rate-value,
//   // .print-invoice-amount-value,
//   // .print-invoice-td-amountBeforeTax-value,
//   // .print-invoice-amountNumber-value {
//   //   text-align: right;
//   // }

//   // `;

//   const styles = `
// #print-invoice-container {
//   max-width: 800px;
//   margin: 0 auto;
//   font-family: 'Arial', sans-serif;
//   color: #333;
//   background-color: white;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   border-radius: 4px;
//   overflow: hidden;
// }

// /* Print-specific styles */
// @media print {
//   @page {
//     size: A4;
//     margin: 0.5cm;
//   }

//   body {
//     background-color: white;
//   }

//   #print-invoice-container {
//     box-shadow: none;
//     border: none;
//     width: 100%;
//     max-width: 100%;
//     border-radius: 0;
//   }

//   .no-print {
//     display: none !important;
//   }
// }

// /* Header section */
// #print-invoice-header {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 20px;
//   border-bottom: 1px solid #e0e0e0;
// }

// .print-invoice-logo {
//   height: 60px;
//   object-fit: contain;
// }

// /* Title styling */
// #print-invoice-title {
//   text-align: center;
//   padding: 12px;
//   background-color: #f8f9fa;
//   border-bottom: 1px solid #e0e0e0;
//   font-weight: bold;
//   font-size: 16px;
//   letter-spacing: 1px;
// }

// /* Invoice and buyer details sections */
// #print-invoice-invoice-details {
//   display: flex;
//   justify-content: space-between;
//   padding: 15px;
//   border-bottom: 1px solid #e0e0e0;
// }

// #print-invoice-buyer-details {
//   padding: 15px;
//   border-bottom: 1px solid #e0e0e0;
// }

// /* Table styling */
// #print-invoice-invoice-table-1,
// #print-invoice-description-table,
// #print-invoice-footer-table,
// #print-invoice-amount-table {
//   width: 100%;
//   border-collapse: collapse;
// }

// .print-invoice-th,
// .print-invoice-td {
//   padding: 8px;
//   text-align: left;
// }

// .print-invoice-th {
//   font-weight: bold;
//   color: #555;
// }

// /* Description table header styling */
// #print-invoice-desc-heading {
//   background-color: #f8f9fa;
//   border-bottom: 2px solid #e0e0e0;
// }

// .print-invoice-desc-th {
//   padding: 10px;
//   text-align: center;
//   border-bottom: 1px solid #e0e0e0;
//   border-right: 1px solid #e0e0e0;
// }

// .print-invoice-desc-th:last-child {
//   border-right: none;
// }

// /* Column width control */
// #print-invoice-sr-no { width: 5%; }
// #print-invoice-desc { width: 45%; }
// #print-invoice-sac { width: 10%; }
// #print-invoice-qty { width: 10%; }
// #print-invoice-rate { width: 15%; }
// #print-invoice-amount { width: 15%; }

// /* Bank and amount details */
// #print-invoice-bank {
//   border-top: 1px solid #e0e0e0;
// }

// #print-invoice-bank-details,
// #print-invoice-amount-details {
//   padding: 15px;
//   vertical-align: top;
// }

// /* Footer section */
// #print-invoice-footer {
//   border-top: 1px solid #e0e0e0;
//   border-bottom: 1px solid #e0e0e0;
// }

// #print-invoice-amountInWords-heading {
//   background-color: #f8f9fa;
// }

// .amountInWords-th {
//   padding: 10px;
//   text-align: left;
//   border-right: 1px solid #e0e0e0;
// }

// .amountInWords-th:last-child {
//   border-right: none;
// }

// /* Bottom section with terms and signature */
// #print-invoice-bottom {
//   display: flex;
//   border-top: 1px solid #e0e0e0;
// }

// #print-invoice-terms {
//   width: 60%;
//   padding: 15px;
//   border-right: 1px solid #e0e0e0;
//   font-size: 0.9em;
//   line-height: 1.5;
// }

// #print-invoice-certified-section {
//   width: 40%;
//   padding: 15px;
//   display: flex;
//   flex-direction: column;
// }

// #print-invoice-certified {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }

// #print-invoice-certified-text {
//   text-align: center;
//   margin-bottom: 80px;
// }

// /* Alignment for monetary values */
// .print-invoice-rate-value,
// .print-invoice-amount-value,
// .print-invoice-td-amountBeforeTax-value,
// .print-invoice-amountNumber-value {
//   text-align: right;
//   font-family: monospace;
// }`;
//   printWindow.document.write(`
// <style>
//   ${styles}
// </style>
// `);
//   printWindow.document.write('</head><body>');
//   printWindow.document.write('<div class="common-view-container">');
//   printWindow.document.write(printContent.innerHTML);
//   printWindow.document.write('</div></body></html>');
//   printWindow.document.close();

//   setTimeout(() => printWindow.print(), 100);

//   setTimeout(() => printWindow.close(), 500);
// };

export const handlePrintInvoice = (printRef) => {
  const printContent = printRef.current;

  // Create a new window
  const printWindow = window.open('', '_blank');

  if (!printWindow) {
    alert('Please allow pop-ups to print the invoice.');
    return;
  }

  // Use document.createElement and appendChild instead of document.write
  const html = document.createElement('html');
  const head = document.createElement('head');
  const style = document.createElement('style');
  style.textContent = `
#print-invoice-container {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

/* Print-specific styles */
@media print {
  @page {
    size: A4;
    margin: 0.5cm;
  }
  
  body {
    background-color: white;
  }
  
  #print-invoice-container {
    box-shadow: none;
    border: none;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
  .no-print {
    display: none !important;
  }
}

/* Header section */
#print-invoice-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.print-invoice-logo {
  height: 60px;
  object-fit: contain;
}

/* Title styling */
#print-invoice-title {
  text-align: center;
  padding: 12px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}

/* Invoice and buyer details sections */
#print-invoice-invoice-details {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

#print-invoice-buyer-details {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

/* Table styling */
#print-invoice-invoice-table-1,
#print-invoice-description-table,
#print-invoice-footer-table,
#print-invoice-amount-table {
  width: 100%;
  border-collapse: collapse;
}

.print-invoice-th,
.print-invoice-td {
  padding: 8px;
  text-align: left;
}

.print-invoice-th {
  font-weight: bold;
  color: #555;
}

/* Description table header styling */
#print-invoice-desc-heading {
  background-color: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
}

.print-invoice-desc-th {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.print-invoice-desc-th:last-child {
  border-right: none;
}

/* Column width control */
#print-invoice-sr-no { width: 5%; }
#print-invoice-desc { width: 45%; }
#print-invoice-sac { width: 10%; }
#print-invoice-qty { width: 10%; }
#print-invoice-rate { width: 15%; }
#print-invoice-amount { width: 15%; }

/* Bank and amount details */
#print-invoice-bank {
  border-top: 1px solid #e0e0e0;
}

#print-invoice-bank-details,
#print-invoice-amount-details {
  padding: 15px;
  vertical-align: top;
}

/* Footer section */
#print-invoice-footer {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

#print-invoice-amountInWords-heading {
  background-color: #f8f9fa;
}

.amountInWords-th {
  padding: 10px;
  text-align: left;
  border-right: 1px solid #e0e0e0;
}

.amountInWords-th:last-child {
  border-right: none;
}

/* Bottom section with terms and signature */
#print-invoice-bottom {
  display: flex;
  border-top: 1px solid #e0e0e0;
}

#print-invoice-terms {
  width: 60%;
  padding: 15px;
  border-right: 1px solid #e0e0e0;
  font-size: 0.9em;
  line-height: 1.5;
}

#print-invoice-certified-section {
  width: 40%;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

#print-invoice-certified {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#print-invoice-certified-text {
  text-align: center;
  margin-bottom: 80px;
}

/* Alignment for monetary values */
.print-invoice-rate-value,
.print-invoice-amount-value,
.print-invoice-td-amountBeforeTax-value,
.print-invoice-amountNumber-value {
  text-align: right;
  font-family: monospace;
}`;

  head.appendChild(style);
  html.appendChild(head);

  const body = document.createElement('body');
  const container = document.createElement('div');
  container.className = 'common-view-container';
  container.innerHTML = printContent.innerHTML;
  body.appendChild(container);
  html.appendChild(body);

  // Add the constructed DOM to the new window
  printWindow.document.replaceChild(html, printWindow.document.documentElement);

  // Set up event listeners to handle window closing
  let printed = false;

  // Listen for the beforeprint event
  printWindow.addEventListener('beforeprint', () => {
    printed = true;
  });

  // Listen for the afterprint event
  printWindow.addEventListener('afterprint', () => {
    printWindow.close();
  });

  // Set up a mechanism to detect if the print dialog was canceled
  const checkPrintDialogClosed = () => {
    if (printWindow.closed) {
      return; // Window already closed
    }

    // If after a reasonable time the print dialog might be closed
    // without printing, close the window
    if (!printed) {
      printWindow.close();
    }
  };

  // Trigger the print dialog after a short delay to ensure content is loaded
  setTimeout(() => {
    printWindow.print();

    // Check if the print dialog is closed after a reasonable time
    setTimeout(checkPrintDialogClosed, 2000);
  }, 300);
};
