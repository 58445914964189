import React, { lazy } from 'react';
import CompanyProvider from '../contexts/companyContext';
import { inboxRoutes } from '../../library/common/constants/navConstants';
import ProjectProvider from '../contexts/projectContext';
import {
  projectConfigurationRoutes,
  companyConfigurationRoutes
} from '../../library/common/constants/navConstants';
import TendersProvider from '../contexts/tendersContext';
import { masterConfigModules } from '../../library/common/constants/mastersConfigConstants';
import OrderProvider from '../contexts/orderContext';
import NewsProvider from '../contexts/newsContext';
import Magazine from '../../screens/Main/Magazine/Magazine';
import LeadProvider from '../contexts/leadContext';
// import AddMagazine from '../../screens/Main/Magazine/AddMagazine/AddMagazine';

//company
const MyCompanies = lazy(() => import('../../screens/Main/Company/MyCompanies/MyCompanies'));
const Companies = lazy(() => import('../../screens/Main/Company/Companies/Companies'));
const InboxTable = lazy(() => import('../../screens/Main/Company/Inbox/InboxTable/InboxTable'));
const AddCompany = lazy(() => import('../../screens/Main/Company/Companies/AddCompany/AddCompany'));

//project
const AddProject = lazy(() => import('../../screens/Main/Project/Projects/AddProject/AddProject'));
const MyProject = lazy(() => import('../../screens/Main/Project/MyProject/MyProject'));
const Project = lazy(() => import('../../screens/Main/Project/Projects/Projects'));
const InboxTableProject = lazy(
  () => import('../../screens/Main/Project/Inbox/InboxTable/InboxTableProject')
);

//users
const Role = lazy(() => import('../../screens/Main/UserManagement/Role/RoleManagement'));
const AddRoleAccess = lazy(() => import('../../screens/Main/UserManagement/Role/AddRoleAccess'));
const User = lazy(() => import('../../screens/Main/UserManagement/User/UserManagement'));

//tenders
const MyTender = lazy(() => import('../../screens/Main/Tender/MyTenders/MyTenders'));
const Tenders = lazy(() => import('../../screens/Main/Tender/Tenders/Tenders'));
const SecondarySubmenuTenders = lazy(
  () => import('../../screens/Main/Tender/SecondarySubmenuTenders/SecondarySubmenuTenders')
);
const AddTender = lazy(() => import('../../screens/Main/Tender/Tenders/AddTender/AddTender'));
const TendersBidAssistTask = lazy(
  () => import('../../screens/Main/Tender/TendersBidAssistTask/TendersBidAssistTask')
);

//order
const AddOrder = lazy(() => import('../../screens/Main/Order/Orders/AddOrder/AddOrder'));
const MyOrder = lazy(() => import('../../screens/Main/Order/MyOrders/MyOrders'));
const Order = lazy(() => import('../../screens/Main/Order/Orders/Orders'));
const SecondarySubmenuOrders = lazy(
  () => import('../../screens/Main/Order/SecondarySubmenuOrders/SecondarySubmenuOrders')
);
const OrdersBidAssistTask = lazy(
  () => import('../../screens/Main/Order/OrdersBidAssistTask/OrdersBidAssistTask')
);

//news
const AddNews = lazy(() => import('../../screens/Main/News/News/AddNews/AddNews'));
const MyNews = lazy(() => import('../../screens/Main/News/MyNews/MyNews'));
const News = lazy(() => import('../../screens/Main/News/News/News'));
const SecondarySubmenuNews = lazy(
  () => import('../../screens/Main/News/SecondarySubmenuNews/SecondarySubmenuNews')
);

//master config
const MasterConfig = lazy(
  () => import('../../screens/Main/MasterConfiguration/MasterConfiguration')
);

//sales crm - dashboard
const SalesCRMDashboard = lazy(
  () => import('../../screens/Main/SalesCRM/SalesCRMDashboard/SalesCRMDashboard')
);

//sales crm - lead
const AddLeads = lazy(() => import('../../screens/Main/SalesCRM/Leads/AddLeads/AddLeads'));
const Leads = lazy(() => import('../../screens/Main/SalesCRM/Leads/Leads'));

// admin-crm - subscription
const Subscriptions = lazy(() => import('../../screens/Main/AdminCRM/Subscriptions/Subscriptions'));
const EditSubscription = lazy(
  () => import('../../screens/Main/AdminCRM/Subscriptions/EditSubscription/EditSubscription')
);

//sales crm - opportunity
const Opportunity = lazy(() => import('../../screens/Main/SalesCRM/Opportunity/Opportunity'));
const EditOpportunity = lazy(
  () => import('../../screens/Main/SalesCRM/Opportunity/EditOpportunity/EditOpportunity')
);

//sales crm - get started
const GetStarted = lazy(() => import('../../screens/Main/SalesCRM/GetStarted/GetStarted'));
const AddGetStarted = lazy(
  () => import('../../screens/Main/SalesCRM/GetStarted/AddGetStarted/AddGetStarted')
);

// sourcing
const SourcingInbox = lazy(() => import('../../screens/Main/Sourcing/Inbox/Inbox'));
const Agency = lazy(() => import('../../screens/Main/Sourcing/Agency/Agency'));
const SourcingCategory = lazy(() => import('../../screens/Main/Sourcing/Category/Category'));

// renewal crm - orders
const RenewalOrders = lazy(
  () => import('../../screens/Main/RenewalCRM/RenewalOrders/RenewalOrders')
);
const EditRenewalOrders = lazy(
  () => import('../../screens/Main/RenewalCRM/RenewalOrders/EditRenewalOrders/EditRenewalOrders')
);

const SubNavMenuCompany = [
  {
    path: 'mycompanies',
    element: (
      <>
        <MyCompanies />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'companies',
    element: (
      <>
        <Companies />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'companies/add-company',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'companies/edit-company-details/:companyID',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'companies/view-company-details/:companyID',
    element: (
      <CompanyProvider>
        <AddCompany viewMode={true} />
      </CompanyProvider>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTable />,
    requiredPermission: ['add', 'delete', 'view']
  }))
];

const SubNavMenuProjects = [
  {
    path: 'myprojects',
    element: <MyProject />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'projects',
    element: <Project />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'projects/add-project',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'projects/edit-project-details/:projectID',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'projects/view-project-details/:projectID',
    element: (
      <ProjectProvider>
        <AddProject viewMode={true} />
      </ProjectProvider>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTableProject />,
    requiredPermission: ['add', 'delete', 'view']
  }))
];

const SubNavMenuTenders = [
  {
    path: 'mytender',
    element: <MyTender />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'tender',
    element: <Tenders />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'tender/add-tender',
    element: (
      <TendersProvider>
        <AddTender />
      </TendersProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'tender/edit-tender-details/:tenderID',
    element: (
      <TendersProvider>
        <AddTender />
      </TendersProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'bidassisttask',
    element: <TendersBidAssistTask />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'tender/view-tender-details/:tenderID',
    element: (
      <TendersProvider>
        <AddTender viewMode={true} />
      </TendersProvider>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuTenders />,
    requiredPermission: ['add', 'delete', 'view']
  }))

  // Add more tenders-related routes as needed
];

// const SubNavMenuContracts = [
//   {
//     path: 'myproject',
//     element: <MyProject />
//   },
//   {
//     path: 'projects',
//     element: (
//       <>
//         <Project />
//       </>
//     )
//   }
//   // Add more contract-related routes as needed
// ];

const SubNavMenuOrder = [
  {
    path: 'myorder',
    element: (
      <>
        <MyOrder />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'order',
    element: (
      <>
        <Order />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'bidassisttask',
    element: <OrdersBidAssistTask />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'order/add-order',
    element: (
      <OrderProvider>
        <AddOrder />
      </OrderProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'order/edit-order-details/:orderID',
    element: (
      <OrderProvider>
        <AddOrder />
      </OrderProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'order/view-order-details/:orderID',
    element: (
      <OrderProvider>
        <AddOrder viewMode={true} />
      </OrderProvider>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuOrders />,
    requiredPermission: ['add', 'delete', 'view']
  }))
];

const SubNavMenuNews = [
  {
    path: 'mynews',
    element: (
      <>
        <MyNews />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'news',
    element: (
      <>
        <News />
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'news/add-news',
    element: (
      <NewsProvider>
        <AddNews />
      </NewsProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'news/edit-news-details/:newsID',
    element: (
      <NewsProvider>
        <AddNews />
      </NewsProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'news/view-news-details/:newsID',
    element: (
      <NewsProvider>
        <AddNews viewMode={true} />
      </NewsProvider>
    ),
    requiredPermission: ['add', 'delete', 'view']
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <SecondarySubmenuNews />,
    requiredPermission: ['add', 'delete', 'view']
  }))
];

const SubNavMenuMagazine = [
  {
    path: 'e-magazine',
    element: <Magazine />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'survey',
    element: (
      <>
        <>UI Not Confirmed</>
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  }
  // {
  //   path: 'e-magazine/add-e-magazine',
  //   element: (
  //       <AddMagazine />
  //   )
  // },
  // {
  //   path: 'e-magazine/add-e-magazine/:magazineID',
  //   element: (
  //       <AddMagazine />
  //   )
  // },
  // Add more contract-related routes as needed
];

const SubNavMenuSourcing = [
  {
    path: 'dashboard',
    element: <>Dashboard</>,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'inbox',
    element: <SourcingInbox />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'agency',
    element: <Agency />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'category-master',
    element: <SourcingCategory />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'report',
    element: (
      <>
        <>UI Not Confirmed</>
      </>
    ),
    requiredPermission: ['add', 'delete', 'view']
  }
];

const SubNavMenuUser = [
  {
    path: 'user',
    element: <User />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'role',
    element: <Role />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'role/edit-role-details/:roleID',
    element: <AddRoleAccess />,
    requiredPermission: ['add', 'delete', 'view']
  }
];

const SubNavMenuProjectConfig = [
  ...(projectConfigurationRoutes?.map((route, index) => ({
    path: route.to,
    element: <MasterConfig key={index} label={masterConfigModules?.project} route={route} />,
    requiredPermission: ['add', 'delete', 'view']
  })) || [])
];

const SubNavMenuCompanyConfig = [
  ...(companyConfigurationRoutes?.map((route, index) => ({
    path: route.to,
    element: <MasterConfig key={index} label={masterConfigModules?.company} route={route} />,
    requiredPermission: ['add', 'delete', 'view']
  })) || [])
];

const SubNavMenuSalesCRM = [
  {
    path: 'dashboard',
    element: <SalesCRMDashboard />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'lead',
    element: <Leads />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'lead/add-lead',
    element: (
      <LeadProvider>
        <AddLeads />
      </LeadProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'lead/edit-lead-details/:leadID',
    element: (
      <LeadProvider>
        <AddLeads />
      </LeadProvider>
    ),
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'opportunity',
    element: <Opportunity />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'opportunity/edit-opportunity-details/:opportunityID/:leadID/:companyID',
    // path: 'opportunity/edit-opportunity-details/:opportunityID',
    element: <EditOpportunity />,
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'get-started',
    element: <GetStarted />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'get-started/add-get-started-lead/:enquiryID',
    element: (
      <LeadProvider>
        <AddGetStarted />
      </LeadProvider>
    ),
    requiredPermission: ['add', 'delete']
  }
];

const SubNavMenuAdminCRM = [
  {
    path: 'dashboard',
    element: <SalesCRMDashboard />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'subscriptions',
    element: <Subscriptions />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'subscriptions/edit-subscription-details/:subscriptionID/:companyID/:leadID',
    element: <EditSubscription />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'report',
    element: <>Report</>,
    requiredPermission: ['add', 'delete', 'view']
  }
];

const SubNavMenuRenewalCRM = [
  {
    path: 'dashboard',
    element: <SalesCRMDashboard />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'orders',
    element: <RenewalOrders />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'orders/edit-renewal-orders/:leadID',
    element: <EditRenewalOrders />,
    requiredPermission: ['add', 'delete']
  },
  {
    path: 'report',
    element: <>Report</>,
    requiredPermission: ['add', 'delete', 'view']
  }
];

const SubNavMenuCSCRM = [
  {
    path: 'dashboard',
    element: <SalesCRMDashboard />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'orders',
    element: <RenewalOrders />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'orders/edit-cs-orders/:leadID',
    element: <EditRenewalOrders />,
    requiredPermission: ['add', 'delete', 'view']
  },
  {
    path: 'report',
    element: <>Report</>,
    requiredPermission: ['add', 'delete', 'view']
  }
];

export {
  SubNavMenuCompany,
  SubNavMenuProjects,
  SubNavMenuTenders,
  // SubNavMenuContracts,
  SubNavMenuSourcing,
  SubNavMenuUser,
  SubNavMenuProjectConfig,
  SubNavMenuCompanyConfig,
  SubNavMenuOrder,
  SubNavMenuNews,
  SubNavMenuMagazine,
  SubNavMenuSalesCRM,
  SubNavMenuAdminCRM,
  SubNavMenuRenewalCRM,
  SubNavMenuCSCRM
};
