/* eslint-disable  */
import ajax from '../utils/ajaxHelper';
import { getTokenFromStorage, removeAuthUserFromStorage } from '../utils/common';

const baseUrl = process.env.REACT_APP_API_URL;
const baseUrlPublic = process.env.REACT_APP_PUBLIC_API_URL;

export const BASE_URL = baseUrl.replace('/api/', '');
// export const BASE_URL_PUBLIC = baseUrlPublic.replace('/api/', '');

export const getRequest = async (url, callback) => {
  const apiUrl = baseUrl + url;
  await ajax
    .get(apiUrl, {
      networkParams: { showLoader: false },
      headers: getToken()
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        unAuthorizedAccess();
      }
    });
};

export const postRequest = async (url, data, callback, publicURL = false) => {
  const apiUrl = (publicURL ? baseUrlPublic : baseUrl) + url;
  await ajax
    .post(apiUrl, data, {
      networkParams: { showLoader: false },
      headers: getToken()
    })
    .then((res) => {
      if (res?.data?.IsSuccess) {
        callback(res?.data);
      } else {
        callback(res);
      }
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        unAuthorizedAccess();
      } else {
        callback(err?.response);
      }
    });
};

export const postDocumentRequest = async (url, data, callback) => {
  const apiUrl = baseUrl + url;
  const token = getToken();
  await ajax
    .post(apiUrl, data, {
      networkParams: { showLoader: false },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: token.Authorization
      }
    })
    .then((res) => {
      console.log(res);
      if (res?.data?.IsSuccess) {
        callback(res?.data);
      } else {
        callback(res);
      }
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        unAuthorizedAccess();
      } else if (err?.status === 422) {
        callback(err?.response);
      }
    });
};

const getToken = () => {
  let reqHeaders = {};
  let authToken = getTokenFromStorage();
  if (authToken) {
    reqHeaders = {
      Authorization: 'Bearer ' + authToken
    };
  }
  return reqHeaders;
};

const unAuthorizedAccess = () => {
  // removeAuthUserFromStorage();
  // window.location = '/';
};
