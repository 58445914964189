import { createSlice } from '@reduxjs/toolkit'

const copyLeadContextSlice = createSlice({
    name: 'copyLeadContext',
    initialState: {
        value: {}
    },
    reducers: {
        copyLead: (state, actions) => {
            state.value = actions.payload;
        },
    }
})
export default copyLeadContextSlice.reducer;

export const { copyLead } = copyLeadContextSlice.actions;
